import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { useAuth } from '../auth.js';
import LoadingButton from './LoadingButton.js';
import { useNavigate } from 'react-router-dom';
import { delay } from 'q';
import { useTranslation } from 'react-i18next';

const WorkForm = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [loading2, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [cv, setCv] = useState(null);
  const [description, setDescription] = useState('');
  const [type, setType] = useState('Trabajar');
  const { user } = useAuth();
  const [error, setError] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    if (error) return;
    sendEmail();
    if (!loading2) {
      navigate('/verification/work');
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
      console.log(fileExtension);
      const allowedExtensions = ['pdf', 'doc', 'docx'];

      if (allowedExtensions.includes(fileExtension)) {
        const maxSize = 490 * 1024; // 500 KB
        if (selectedFile.size > maxSize) {
          setError(t('WorkForm.errorTamaño'));
        } else {
          setError('');
          setCv(selectedFile);
        }
      } else {
        setError(t('WorkForm.errorExtension'));
      }
    }
  };

  const sendEmail = () => {
    const formElement = document.getElementById('formWork');
    const serviceId = 'sleepzzoneId';
    const templateId = 'template_xu6jbsl';
    const userId = 'm_F9K9HuFgibYIL2W';
    const templateParams = {
      user_type: 'fff',
      user_code: user ? user.uid : 'Null',
    };

    setLoading(true);
    emailjs
      .sendForm(serviceId, templateId, formElement, userId, templateParams)
      .then((response) => {
        console.log('Email sent successfully:', response);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });

    delay(5000).then(() => {
      setLoading(false);
    });
  };

  return (
    <div>
      <h2 className="text-xl lg:text-2xl mb-3 lg:mb-4 text-white font-black">
        {t('WorkForm.rellena')}
      </h2>
      <form
        id="formWork"
        className="flex flex-col gap-6"
        onSubmit={handleSubmit}
      >
        <div className="form-group">
          <input
            type="text"
            id="name"
            name="user_name"
            value={name}
            placeholder={t('WorkForm.nombre')}
            onChange={(e) => setName(e.target.value)}
            className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            id="email"
            name="user_mail"
            value={user && user.email ? user.email : email}
            placeholder={t('WorkForm.email')}
            onChange={(e) => setEmail(e.target.value)}
            className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            id="telephone"
            name="user_telephone"
            value={telephone}
            placeholder={t('WorkForm.telefono')}
            onChange={(e) => setTelephone(e.target.value)}
            className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
            required
          />
        </div>
        <div className="form-group">
          <textarea
            type="text"
            id="description"
            name="user_description"
            value={description}
            placeholder={t('WorkForm.descripcion')}
            onChange={(e) => setDescription(e.target.value)}
            className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
            style={{ paddingTop: '10px', paddingBottom: '10px' }}
            required
          />
        </div>
        <div className="form-group">
          <select
            id="type"
            name="user_type"
            value="Trabajar"
            onChange={(e) => setType(e.target.value)}
            className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
            style={{ paddingTop: '10px', paddingBottom: '10px' }}
            required
          >
            <option value="Trabajar">{t('WorkForm.trabajar')}</option>
            <option value="Colaborar">{t('WorkForm.colaborar')}</option>
          </select>
        </div>
        <div className="form-group">
          <input
            type="file"
            id="cv"
            name="user_cv"
            accept=".pdf, .doc, .docx"
            placeholder="Sube tu CV"
            onChange={handleFileChange}
            className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
            style={{ paddingTop: '10px', paddingBottom: '10px' }}
            required
          />
          {error && <p className="text-red-500">{error}</p>}
        </div>

        <LoadingButton
          onClick={handleSubmit}
          loading={loading2}
          className="rounded-lg inline-block text-white blue-btn py-3.5 px-6 w-100 font-black border-2"
          loadingMessage={t('BusinessForm.enviando')}
          disabled={!!error}
        >
          {t('WorkForm.boton')}
        </LoadingButton>
      </form>
    </div>
  );
};

export default WorkForm;
