import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../../auth.js';
import * as apiService from '../../api-service.js';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Preloader from '../../component/Preloader.js';
import LoadingButton from '../../component/LoadingButton';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import SideMenu from '../../component/SideMenu.js';

export default function ClinicHistory() {
  const { t } = useTranslation();
  const { user, loading, signOut } = useAuth();
  const [dataState, setDataState] = useState(undefined);
  const userDataRef = useRef({});
  const clinicHistoryRef = useRef({});
  const navigate = useNavigate();
  const [loading2, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (!loading) {
        if (user) {
          setDataState('loading');
          const userIdToken = await user.getIdToken();
          try {
            const { userData, clinicHistory } = await apiService.getUserData({
              userIdToken,
              userId: user.uid,
            });

            userDataRef.current = userData;

            if (clinicHistory) {
              clinicHistoryRef.current = clinicHistory;
              setAge(clinicHistory.age || '');
              setHeight(clinicHistory.height || '');
              setWeight(clinicHistory.weight || '');
              setDiabetes(
                clinicHistory.diabetes === 0
                  ? 'no'
                  : clinicHistory.diabetes === 1
                  ? 'yes'
                  : ''
              );
              setGenre(clinicHistory.genre || 'Masculino');
              setAllergy(clinicHistory.allergy || '');
              setMedication(clinicHistory.medication || '');
            } else {
              setAge('');
              setHeight('');
              setWeight('');
              setDiabetes('');
              setGenre('Masculino');
              setAllergy('');
              setMedication('');
            }
            setDataState('success');
          } catch {
            setDataState('error');
          }
        }
      }
    })();
  }, [user, loading]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const nullData = null;
    const clinicHistory = {
      imc: nullData,
      age,
      genre,
      height,
      weight,
      diabetes: diabetes === 'no' ? 0 : 1,
      allergy,
      medication,
    };

    setLoading(true);

    const userIdToken = await user.getIdToken();

    try {
      const success = await apiService.setClinicHistoryData({
        userIdToken,
        userId: user.uid,
        clinicHistory,
      });
      setLoading(false);
      if (success) {
        // ???
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setDataState('error');
    } finally {
      setLoading(false);
    }
  };

  const handleSignOut = async () => {
    await signOut();
    navigate('/');
  };

  const [age, setAge] = useState('');
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [genre, setGenre] = useState('Masculino');
  const [diabetes, setDiabetes] = useState('');
  const [allergy, setAllergy] = useState('');
  const [medication, setMedication] = useState('');

  const handleSelectGenre = (event) => {
    const { value } = event.target;
    setGenre(value);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case 'height':
        setHeight(value);
        break;
      case 'age':
        setAge(value);
        break;
      case 'weight':
        setWeight(value);
        break;
      case 'allergy':
        setAllergy(value);
        break;
      case 'medication':
        setMedication(value);
        break;
      default:
        break;
    }
  };

  const handleDiabetesChange = (event) => {
    const { value } = event.target;
    setDiabetes(value);
  };

  const child = loading ? (
    <></>
  ) : user ? (
    dataState === 'loading' ? (
      <Preloader></Preloader>
    ) : dataState === 'error' ? (
      <p>An error occured.</p>
    ) : dataState === 'success' ? (
      <div className="login-page relative">
        <div className="wrapper mx-auto px-5 lg:px-0">
          <nav>
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto py-4">
              <a href="/" className="flex items-center">
                <img
                  src="/images/logo.webp"
                  className="h-8 mr-3"
                  alt="SleepZzone Logo"
                />
              </a>

              <div className="w-full md:block md:w-auto" id="navbar-default">
                <ul className="font-medium flex justify-between lg:justify-normal items-center lg:p-4 md:p-0 mt-4 md:space-x-8 md:mt-0 md:border-0">
                  <li>
                    <div className="profile-bt-text">
                      <h4 className="text-base font-semibold opacity-60 text-white">
                        {t('ClinicHistory.bienvenido')}{' '}
                        {userDataRef.current.name}
                      </h4>
                      <p className="text-xs text-white premium-text font-medium">
                        {userDataRef.current.premium ? 'Premium' : 'Premium'}
                      </p>
                    </div>
                  </li>
                  <li>
                    <button
                      onClick={handleSignOut}
                      className="rounded-lg inline-block text-white text-base bg-zinc-800 hover:bg-zinc-700 py-2 px-6 font-black"
                    >
                      {t('ClinicHistory.cerrar')}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          <div class="flex">
            <div className="button-column-profile pt-4 lg:pt-8 pb-12">
              <SideMenu />
            </div>

            <div className="pt-4 lg:pt-8 pb-12 ">
              <div>
                <h3 className="text-white text-2xl pb-4 lg:text-4xl font-black">
                  {t('Profile.titulo')}
                </h3>
                <div className="button-top m-8 flex gap-8 text-lg justify-between">
                  <Link to="/profile">
                    <button class="h-16 rounded-lg text-white gray-btn hover:scale-110 hover:bg-sky-500 transition duration-500 px-4 font-semibold lg:px-6 lg:font-black">
                      {t('Profile.valoration')}
                    </button>
                  </Link>
                  <Link to="/PersonalInfo">
                    <button class="h-16 rounded-lg text-white gray-btn hover:scale-110 hover:bg-sky-500 transition duration-500 px-4 font-semibold lg:px-6 lg:font-black">
                      {t('Profile.info')}
                    </button>
                  </Link>
                  <Link to="/clinicHistory">
                    <button class="h-16 rounded-lg text-white gray-btn hover:scale-110 hover:bg-sky-500 transition duration-500 px-4 font-semibold lg:px-6 lg:font-black">
                      {t('Profile.clinic')}
                    </button>
                  </Link>
                </div>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-5">
                  <label
                    htmlFor="select1"
                    className="text-xl text-white font-black	mb-2 block"
                  >
                    {t('ClinicHistory.género')}
                  </label>
                  <select
                    className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
                    id="select1"
                    value={genre}
                    onChange={handleSelectGenre}
                  >
                    <option value="Masculino">
                      {t('ClinicHistory.masculino')}
                    </option>
                    <option value="Femenino">
                      {t('ClinicHistory.femenino')}
                    </option>
                  </select>
                </div>

                <div className="form-group mb-5">
                  <label
                    htmlFor="select2"
                    className="text-xl text-white font-black	mb-2 block"
                  >
                    {t('ClinicHistory.edad')}
                  </label>
                  <input
                    name="age"
                    className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
                    placeholder={t('ClinicHistory.placeholderEdad')}
                    type="number"
                    value={age}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group mb-2">
                  <label
                    htmlFor="select2"
                    className="text-xl text-white font-black	mb-2 block"
                  >
                    {t('ClinicHistory.altura')}
                  </label>
                  <div className="grid grid-cols-8 gap-x-5 ">
                    <div className="form-group mb-5 col-span-6">
                      <input
                        name="height"
                        className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
                        placeholder={t('ClinicHistory.placeholderAltura')}
                        type="number"
                        value={height}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="form-group mb-5 col-span-2">
                      <select
                        className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
                        id="select2"
                        value={'option1'}
                      >
                        <option value="option1">cms</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="select3"
                    className="text-xl text-white font-black	mb-2 block"
                  >
                    {t('ClinicHistory.peso')}
                  </label>
                  <div className="grid grid-cols-8 gap-x-5 ">
                    <div className="form-group mb-5 col-span-6">
                      <input
                        name="weight"
                        className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
                        placeholder={t('ClinicHistory.placeholderPeso')}
                        type="number"
                        value={weight}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="form-group mb-5 col-span-2">
                      <select
                        className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
                        id="select3"
                        value={'option1'}
                      >
                        <option value="option1">kgs</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-5">
                  <label
                    htmlFor="select3"
                    className="text-xl text-white font-black	mb-2 block"
                  >
                    {t('ClinicHistory.diabetes')}
                  </label>
                  <div className="yes-no-btn">
                    <label>
                      <input
                        type="radio"
                        value="yes"
                        checked={diabetes === 'yes'}
                        onChange={handleDiabetesChange}
                      />
                      <span>{t('ClinicHistory.si')}</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="no"
                        checked={diabetes === 'no'}
                        onChange={handleDiabetesChange}
                      />
                      <span>No</span>
                    </label>
                  </div>
                </div>

                <div className="form-group mb-5">
                  <label
                    htmlFor="select2"
                    className="text-xl text-white font-black	mb-2 block"
                  >
                    {t('ClinicHistory.alergia')}
                  </label>
                  <input
                    name="allergy"
                    className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
                    placeholder={t('ClinicHistory.placeholderAlergia')}
                    type="text"
                    value={allergy}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group mb-5">
                  <label
                    htmlFor="select2"
                    className="text-xl text-white font-black	mb-2 block"
                  >
                    {t('ClinicHistory.medicacion')}
                  </label>
                  <input
                    name="medication"
                    className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
                    placeholder={t('ClinicHistory.placeholderMedicacion')}
                    type="text"
                    value={medication}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <LoadingButton
                  loading={loading2}
                  onClick={handleSubmit}
                  className="rounded-lg text-white blue-btn py-2 px-4 font-semibold lg:px-6 lg:font-black profile-list-button"
                  loadingMessage="Cambiando tus datos..."
                >
                  {t('ClinicHistory.boton')}
                </LoadingButton>
              </form>
            </div>
          </div>
        </div>
      </div>
    ) : undefined
  ) : (
    <div className="login-page relative">
      <div className="wrapper mx-auto px-5 lg:px-0 text-center py-10 lg:py-20">
        <h4 className="text-4xl mb-4">You're not logged in</h4>
        <a
          href="/"
          className="rounded-lg inline-block text-white text-base bg-zinc-800 hover:bg-zinc-700 py-2 px-6 font-black"
        >
          Back to home
        </a>
      </div>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>
          Perfil - Historial Clínico: Rellena tus datos personales para poder
          saber más de ti y ayudarte a dormir mejor de manera personalizada |
          SleepZzone{' '}
        </title>
        <meta
          name="description"
          content="Con tu pefil completo, podrás conocer más sobre tus hábitos de sueño, consejos para dormir mejor y cómo tus experiencias pueden ayudarte a lograr una noche de sueño más reparadora."
        />
        <meta
          name="keywords"
          content="Dormir, descansar, bienestar, descanso, sueño reparador, consejos para dormir, rendimiento, aprender a dormir"
        />
      </Helmet>
      <div>{child}</div>
    </>
  );
}
