import * as apiService from "./api-service";
import { useLocation, Navigate } from "react-router-dom";
import { useEffect, createContext, useContext, useState } from "react";
import {
  getAuth,
  signInWithCustomToken,
  signOut as firebaseSignOut,
} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useAuth0 } from "@auth0/auth0-react";

export function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();

  useEffect(() => {}, [auth.loading]);

  if (auth.loading) {
    return undefined; // Should have loading indicator
  }

  if (auth?.user) {
    return children;
  } else {
    // return <p>Usuario no registrado</p>;
    return <Navigate to="/" state={{ from: location }} replace />;
  }
}

export function RequireNotAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();

  useEffect(() => {}, [auth.loading]);

  if (auth.loading) {
    return undefined; // Should have loading indicator
  }

  if (!auth.user) {
    return children;
  }

  return <Navigate to="/profile" state={{ from: location }} replace />;
}

export const AuthContext = createContext(undefined);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const {
    user: userAuth0Context,
    logout,
    isAuthenticated: isAuthenticatedContext,
    getAccessTokenSilently: auth0Token,
  } = useAuth0();
  const [isPremium, setIsPremium] = useState(false);

  const auth = getAuth();
  const [user, loading] = useAuthState(auth);

  const signIn = async (uuid) => {
    const { token, isVerified } = await apiService.signIn({
      uuid,
    });

    if (!isVerified) {
      throw new Error("Correo electrónico no verificado");
    }

    await signInWithCustomToken(auth, token);
  };

  const signUp = async ({
    name,
    email,
    password,
    telephone,
    disCode,
    lastName,
    uuid,
  }) => {
    const { token } = await apiService.signUp({
      name,
      lastName,
      email,
      password,
      telephone,
      disCode,
      uuid,
    });
    await signInWithCustomToken(getAuth(), token);
  };

  const signOut = async () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
    const auth = getAuth();
    await firebaseSignOut(auth);
  };

  const value = {
    user,
    loading,
    signIn,
    signOut,
    signUp,
    userAuth0Context,
    isAuthenticatedContext,
    auth0Token,
    isPremium,
    setIsPremium,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
