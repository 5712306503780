import React from "react";
import BusinessForm from "../component/BusinessForm";
import MobileForm from "../component/MobileForm";
import WorkForm from "../component/WorkForm";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer.js";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const ContactPage = () => {
  const { t } = useTranslation();
  const { type } = useParams();
  const isDarkPage = true;
  const isInnerPage = true;

  const title =
    type === "business"
      ? t("Contact.titleBusiness")
      : type === "mobile"
      ? t("Contact.titleMobile")
      : type === "work"
      ? t("Contact.titleWork")
      : type === "collaborate"
      ? t("Contact.titleColaborate")
      : t("Contact.titlePremium")

  const text =
    type === "business"
      ? t("Contact.textBusiness")
      : type === "mobile"
      ? t("Contact.textMobile")
      : type === "work"
      ? t("Contact.textWork")
      : type === "collaborate"
      ? t("Contact.textColaborate")
      : t("Contact.textPremium")

  return (
    <>
      <Helmet>
        <title>Contacto: Contáctanos para ayudarte a mejorar tu sueño | SleepZzone</title>
        <meta name="description" content="Ponte en contacto con el equipo de SleepZzone. Envíanos tus datos, comentarios y estaremos encantados de ayudarte." />
        <meta name="keywords" content="Contacto, Consultas, Comentarios, Sugerencias, Ayuda, Soporte" />
      </Helmet>
      <Navbar isInnerPage={isInnerPage} />
      <div className="login-page py-32 lg:py-16">
        <div className="wrapper mx-auto px-5 lg:px-0">
          <div className="grid lg:grid-cols-2 gap-8 lg:gap-16 lg:items-center">
            <div className="description">
              <h2 className="text-white text-3xl lg:text-4xl font-black mb-3 lg:mb-5">
                {title}
              </h2>
              <p className="text-white opacity-60 text-base lg:text-lg">
                {text}
              </p>
            </div>
            <div className="form grow">
              {type === "business" ? (
                <BusinessForm />
              ) : type === "mobile" || type === "premium"? (
                <MobileForm />
              ) : (
                <WorkForm />
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer isDarkPage={isDarkPage} />
    </>
  );
};

export default ContactPage;
