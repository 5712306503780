import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function FooterSmall({isDarkPage}) {
    const { t } = useTranslation();
    const FooterClass = isDarkPage ? 'footer-dark' : 'footer-normal';
  return (
   <footer className={`relative footer-before ${FooterClass}`}>
    <div className="footer-bottom py-8">
        <div className="wrapper mx-auto">
            <div className="app-btn-footer space-x-4 flex justify-center mb-5 text-center">
                <a ><img src="/images/app-store.svg" alt="Descárgate la app deSleepZzone en la App Store"/></a>
                <a ><img src="/images/play-store.svg" alt="Descárgate la app de SleepZzone en Google Play"/></a>
            </div>
            <ul className="footer-bottomlist text-center space-x-8 flex justify-center">
                <li><a >© 2023 SleepZzone</a></li>
                <li><Link alt="Política de privacidad" to="/politica-de-privacidad">{t("Footer.Info.privacidad")}</Link></li>
                <li><a alt="Cookies">Cookies</a></li>
                <li><Link to="/conditions" alt="Términos y Condiciones">{t("Footer.Info.condiciones")}</Link></li>
                <li><a alt="Información de seguridad" >{t("Footer.Info.seguridad")}</a></li>
            </ul>
        </div>
    </div>
   </footer>
  )
}
