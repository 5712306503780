import React from "react";
import { useTranslation } from "react-i18next";

export default function Solutions() {
  const { t } = useTranslation();
  return (
    <>
      <section id="solutions" className="solution-sec py-12 lg:py-24 relative z-10 before:content-['']">
        <div className="wrapper mx-auto relative z-10 px-5 lg:px-0">
          <div className="sol-header">
            <h2 className="text-center font-black mb-8 lg:mb-16 text-3xl lg:text-5xl">
              {t("Solutions.titulo1")}
              <span className="blue-text"> {t("Solutions.titulo2")} </span> {t("Solutions.titulo3")}
            </h2>
          </div>
          <div className="flex flex-col lg:flex-row text-center gap-6 lg:gap-4">
            <div className="flex-box lg:w-3/12">
              <h2 className="leading-none	font-semibold text-3xl lg:text-6xl mb-3 lg:mb-7">50%</h2>
              <p>
                {t("Solutions.porcentaje1")}
              </p>
            </div>
            <div className="flex-box lg:w-3/12">
              <h2 className="leading-none	font-semibold text-3xl lg:text-6xl mb-3 lg:mb-7">20%</h2>
              <p>
                {t("Solutions.porcentaje2")}
              </p>
            </div>
            <div className="flex-box lg:w-3/12">
              <h2 className="leading-none font-semibold text-3xl lg:text-6xl mb-3 lg:mb-7">60%</h2>
              <p>
                {t("Solutions.porcentaje3")}
              </p>
            </div>
            <div className="flex-box lg:w-3/12">
              <h2 className="leading-none font-semibold text-3xl lg:text-6xl mb-3 lg:mb-7">50%</h2>
              <p>
                {t("Solutions.porcentaje4")}
              </p>
            </div>
          </div>
          <div className="text-center mt-8 lg:mt-16">
            <a
              href="/contact/business"
              className="rounded-lg inline-block text-white mr-6 blue-btn py-3.5 px-6 font-black border-2"
            >
              {t("Solutions.boton1")}
            </a>
            <a
              href="/roipage"
              className="rounded-lg inline-block text-white mr-6 blue-btn py-3.5 px-6 font-black border-2"
            >
              {t("Solutions.boton2")}
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
