import React, { useEffect } from 'react';
import FooterSmall from '../component/FooterSmall.js';
import Navbar from '../component/Navbar.js';
import { Helmet } from 'react-helmet';

const PrivacyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Política de Privacidad - SleepZzone</title>
        <meta
          name="description"
          content="Lee nuestra Política de Privacidad para entender cómo recopilamos, utilizamos y protegemos tus datos personales en SleepZzone."
        />
        <meta
          name="keywords"
          content="Política de privacidad, Datos personales, Protección de datos, Privacidad en línea"
        />
      </Helmet>
      <div className="bg-gray-100">
        <Navbar isInnerPage={true} />
        <div className="max-w-3xl mx-auto p-5">
          <section className="bg-white p-5 mb-5 shadow-md">
            <h1 className="text-2xl text-gray-800 font-bold mb-2">
              Políticas de privacidad en SleepZzone
            </h1>
            <p className="text-base text-gray-800 leading-relaxed">
              El usuario queda informado sobre el tratamiento de los datos
              suministrados durante la navegación en la página Web y los que se
              generen como consecuencia de la utilización de esta, incluidas, en
              su caso, las comunicaciones o las transferencias internacionales
              de los datos que pudieran realizarse, con las finalidades
              indicadas en el apartado En caso de que los datos facilitados se
              refieran a terceras personas físicas distintas del usuario, éste
              garantiza haber recabado y contar con el consentimiento previo de
              los mismos para la comunicación de sus datos y haberles informado,
              con carácter previo a facilitarlos, de las finalidades del
              tratamiento, comunicaciones y demás términos previstos en el
              apartado Información sobre Protección de Datos. Garantizas que
              eres mayor de 18 años y que todos los datos facilitados te
              corresponden. Si eres menor de 16 años antes de proceder a incluir
              tus datos personales en los formularios habilitados debes contar
              con la autorización previa de tus padres, tutores o representantes
              legales, quienes, según la legislación vigente, serán considerados
              responsables de todos los actos que realices. El usuario garantiza
              la exactitud y veracidad de los datos facilitados, adquiriendo el
              compromiso de comunicar a SLEEPZZONE LAND SL cualquier cambio en
              los mismos. La utilización de esta web está sujeta a las
              Condiciones de Uso (enlace) y a la Política de Privacidad y
              Tratamiento de Datos Personales, que se detallan a continuación,
              así como a la Política de Cookies (enlace). Le rogamos que lea
              atentamente.
            </p>
          </section>
          <section className="bg-white p-5 mb-5 shadow-md">
            <h1 className="text-2xl text-gray-800 font-bold mb-2">
              Información de protección de datos
            </h1>
            <h2 className="text-lg text-gray-500 mb-2">
              ¿Quién es el responsable del tratamiento de tus datos personales?
            </h2>
            <ul className="list-none pl-0">
              <li>Denominación social: SLEEPZZONE LAND SL</li>
              <li>Nombre comercial: SLEEPZZONE</li>
              <li>Número de Identificación Fiscal: B88586219</li>
              <li>Domicilio Social: CALLE ORFILA, 7 2 º. 28010, MADRID</li>
              <li>Email de contacto: info@sleepzzone.com</li>
              <li>Teléfono de contacto: 865 660 465 / 603 367 585</li>
              <li>
                Inscrita en el registro mercantil de Madrid, al tomo 40245,
                folio 37, hoja número M-715027, inscripción 1ª.
              </li>
            </ul>
          </section>
          <section className="bg-white p-5 mb-5 shadow-md">
            <h1 className="text-2xl text-gray-800 font-bold mb-2">
              ¿Qué datos recabamos?
            </h1>
            <p className="text-base text-gray-800 leading-relaxed mb-5">
              Los datos que SLEEPZZONE LAND SL conserva son los siguientes:
              <ul className="list-none pl-0 mt-5 mb-5">
                <li>
                  Datos identificativos: nombre, apellido, correo electrónico,
                  email, teléfono.
                </li>
                <li>
                  Datos de salud, como por ejemplo la información de tu perfil
                  de salud.
                </li>
              </ul>
            </p>
            <p className="text-base text-gray-800 leading-relaxed">
              En caso de no suministrar al menos tales datos, considerados como
              necesarios, SLEEPZZONE LAND SL no podrá gestionar tu solicitud,
              consulta o contratación.
            </p>
          </section>
          <section className="bg-white p-5 mb-5 shadow-md">
            <h1 className="text-2xl text-gray-800 font-bold mb-2">
              ¿Con qué finalidad tratamos tus datos personales?
            </h1>
            <p className="text-base text-gray-800 leading-relaxed">
              A continuación, se detallan las finalidades de los tratamientos:
            </p>
            <p className="text-base text-gray-800 leading-relaxed">
              - En relación con los formularios facilitados:
              <ul className="list-none pl-0 mt-5 mb-5">
                <li>Tramitar el registro en la aplicación.</li>
                <li>
                  Gestionar la solicitud y contratación de nuestros servicios y
                  productos.
                </li>
                <li>
                  Realizar estudios y cálculos estadísticos, encuestas, análisis
                  de tendencias del mercado y control de calidad.
                </li>
                <li>
                  Elaborar los perfiles necesarios para la personalización y el
                  acompañamiento diseñado específicamente para ti dentro de
                  SLEEPZZONE en base a tus interacciones con nosotros.
                </li>
                <li>
                  Tramitar tu solicitud de alta como profesional del cuadro
                  médico.
                </li>
                <li>
                  Atender y gestionar tus sugerencias, reclamaciones y otras
                  consultas que realices a través del formulario correspondiente
                  o de los números de teléfono del centro de soporte facilitados
                  a tal efecto.
                </li>
              </ul>
            </p>
            <p className="text-base text-gray-800 leading-relaxed">
              - En relación con la gestión de tu área privada:
              <ul className="list-none pl-0 mt-5 mb-5">
                <li>
                  Facilitarte el acceso para la gestión y consulta de tus datos,
                  así como los resultados de los servicios y productos
                  contratados.
                </li>
              </ul>
            </p>
            <p className="text-base text-gray-800 leading-relaxed">
              - Respecto al envío de comunicaciones comerciales:
              <ul className="list-none pl-0 mt-5 mb-5">
                <li>
                  Trataremos tus datos para enviarte información y publicidad,
                  incluso por vía electrónica, sobre ofertas, productos,
                  recomendaciones, servicios, promociones, obsequios y campañas
                  de fidelización de SLEEPZZONE, en base a la elaboración de
                  perfiles mediante estudios de marketing y estadísticos a
                  partir de tus compras previas y la información que hayas
                  proporcionado en tu perfil de salud para que dichas
                  comunicaciones se ajusten todo lo posible a tus necesidades y
                  preferencias.
                </li>
                <li>
                  Enviarte comunicaciones comerciales de SLEEPZZONE y de
                  empresas colaboradoras con las que tengamos acuerdos
                  relevantes para ti.
                </li>
              </ul>
            </p>
          </section>
          <section className="bg-white p-5 mb-5 shadow-md">
            <h1 className="text-2xl text-gray-800 font-bold mb-2">
              ¿Por cuánto tiempo conservaremos tus datos personales?
            </h1>
            <p className="text-base text-gray-800 leading-relaxed">
              Trataremos tus datos hasta que decidas darte de baja de la
              aplicación. Una vez ocurrido esto, se conservarán bloqueados
              durante el plazo de prescripción de las acciones derivadas de las
              relaciones contractuales.
            </p>
          </section>
          <section className="bg-white p-5 mb-5 shadow-md">
            <h1 className="text-2xl text-gray-800 font-bold mb-2">
              ¿A qué destinatarios se comunicarán tus datos?
            </h1>
            <p className="text-base text-gray-800 leading-relaxed">
              SLEEPZZONE LAND SL, podrá comunicar tus datos, exclusivamente para
              las finalidades indicadas en el apartado ¿Con qué finalidad
              tratamos tus datos personales? a los siguientes destinatarios:
            </p>
            <p className="text-base text-gray-800 leading-relaxed">
              <strong>Posibles destinatarios:</strong>
              <ul className="list-none pl-0 mt-5 mb-5">
                <li>
                  Administraciones Públicas competentes, en los casos previstos
                  en la Ley y para los fines en ella definidos.
                </li>
                <li>
                  Entidades Financieras y/o Bancarias para la gestión de cobros
                  y pagos.
                </li>
                <li>
                  Compañías de seguros, reaseguros y corredurías de seguros,
                  para la adecuada prestación del servicio contratado, la
                  gestión y cobertura de riesgos.
                </li>
                <li>
                  Entidades prestadoras de servicios de salud, en caso de que lo
                  solicites.
                </li>
              </ul>
            </p>
          </section>
        </div>
        <FooterSmall />
      </div>
    </>
  );
};

export default PrivacyPage;
