import React from 'react';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
export default function Testimonials() {
  const { t } = useTranslation();
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="testimonial-sec py-8 lg:py-16 relative lg:mb-32">
        <div className="wrapper mx-auto px-5 lg:px-0">
          <h2 className="font-black text-3xl lg:text-5xl mb-8 text-center">
            {t('Testimonials.titulo')}
          </h2>
          <div className="slider-outer">
            <Slider {...settings}>
              <div className="lg:px-4">
                <div className="test-slide p-8	rounded-2xl">
                  <p>{t('Testimonials.testimonio1')}</p>

                  <h6>– Ernestina Menasalva </h6>
                  <p class="text-under-h6">{t('Testimonials.cargo1')}</p>
                </div>
              </div>
              <div className="lg:px-4">
                <div className="test-slide p-8	rounded-2xl">
                  <p>{t('Testimonials.testimonio2')}</p>

                  <h6>– Santiago Sánchez </h6>
                  <p class="santiago">{t('Testimonials.cargo2')}</p>
                </div>
              </div>
              <div className="lg:px-4">
                <div className="test-slide p-8	rounded-2xl">
                  <p>{t('Testimonials.testimonio3')}</p>
                  <h6>– Raúl Jimenez </h6>
                  <p class="santiago">{t('Testimonials.cargo3')}</p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
}
