import React, { useEffect } from 'react';
import FooterSmall from '../component/FooterSmall.js';
import Navbar from '../component/Navbar.js';

const ConditionsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar isInnerPage={true} />
      <div className="container mx-auto px-4 py-8">
        <section className="mb-8">
          <h1 className="text-3xl font-bold text-gray-800 mb-4">
            AVISO LEGAL: Condiciones generales de uso
          </h1>
          <p className="text-gray-700 leading-relaxed mb-4">
            Estas son las Condiciones Generales de Uso (“Condiciones de Uso”)
            que regulan la utilización del sitio Web y de la App (“Sitio”) que
            SLEEPZZONE pone a disposición del Usuario. Las Condiciones de Uso
            junto con el Aviso Legal, la Política de Privacidad y la Política de
            Cookies constituyen en su conjunto los términos y condiciones que
            rigen el acceso y uso de la parte pública y privada del Sitio
            ("Términos y Condiciones del Uso").
          </p>

          <p className="text-gray-700 leading-relaxed mb-4">
            El acceso y utilización del Sitio por parte del Usuario supone que
            ha leído los “Términos y Condiciones de Uso” así cómo el Aviso
            Legal, la Política de Privacidad y la Política de Cookies, los
            acepta en su totalidad y se obliga a cumplirlos por completo
            incluyendo el consentimiento explícito y autorizo expresamente la
            cesión y venta nacional y/o internacional de los datos recabados
            según se indica en la Política de Privacidad.
          </p>

          <p className="text-gray-700 leading-relaxed mb-4">
            Información básica sobre protección de datos.
          </p>

          <p className="text-gray-700 leading-relaxed mb-4">
            Responsable del tratamiento: SLEEPZZONE LAND SL
          </p>

          <p className="text-gray-700 leading-relaxed mb-4">
            Finalidad: A título enunciativo el diseño, valoración, diagnóstico,
            tratamiento, seguimiento, evaluación, formación, I+D+i,
            comunicación, divulgación, obra social, etc.. del sueño a nivel
            particular o colectivo.
          </p>

          <p className="text-gray-700 leading-relaxed mb-4">
            Legitimación para el tratamiento: Consentimiento explícito del
            interesado otorgado al solicitar la inscripción.
          </p>

          <p className="text-gray-700 leading-relaxed mb-4">
            Cesión de datos: Se cederán datos a colaboradores del responsable
            para la prestación del servicio, y asimismo, se autoriza
            expresamente la posible venta de los datos a terceros.
          </p>

          <p className="text-gray-700 leading-relaxed mb-4">
            Derechos: Podrá ejercitar los derechos de Acceso, Rectificación,
            Supresión, Oposición, Portabilidad y, en su caso Limitación, como se
            explica detalladamente en enlace Política de Privacidad.
          </p>

          <p className="text-gray-700 leading-relaxed mb-4">
            El Usuario acepta que el acceso y uso del Sitio tiene lugar libre y
            conscientemente, bajo su única responsabilidad, y se compromete a
            hacer un uso adecuado y lícito del Sitio de conformidad con la
            legislación aplicable y los Términos y Condiciones de Uso. Deberá
            abstenerse de hacer un uso no autorizado o fraudulento del Sitio,
            utilizarlo con fines o efectos ilícitos, ilegales o contrarios a lo
            establecido en la legislación aplicable y en los Términos y
            Condiciones de Uso.
          </p>

          <p className="text-gray-700 leading-relaxed mb-4">
            SLEEPZZONE se reserva el derecho a actualizar, modificar o eliminar
            partes de los Términos y Condiciones de Uso, que serán comunicadas
            al Usuario con el nuevo acceso, en cuyo caso publicará las
            modificaciones en el Sitio e indicará la fecha de la última
            revisión. El Usuario es el responsable de visitar el Sitio
            regularmente y consultar tales actualizaciones.
          </p>

          <p className="text-gray-700 leading-relaxed mb-4">
            SLEEPZZONE se reserva el derecho a denegar o retirar el acceso al
            Sitio y/o a los servicios, en cualquier momento y sin necesidad de
            preaviso a todos aquellos Usuarios que incumplan los Términos y
            Condiciones de Uso.
          </p>

          <ol className="list-decimal pl-6 space-y-2">
            <li>¿Qué es SLEEPZZONE?</li>
            <li>Identificación de las partes</li>
            <li>Derechos de propiedad intelectual e industrial</li>
            <li>¿Qué puedes hacer con nuestra App?</li>
            <li>Productos y servicios</li>
            <li>Acceso y uso</li>
            <li>Registro</li>
            <li>Soporte y centro de ayuda</li>
            <li>Ley aplicable y jurisdicción</li>
          </ol>
        </section>

        <section className="mb-8">
          <h1 className="text-2xl font-semibold text-gray-800 mb-4">
            1. ¿Qué es SLEEPZZONE?
          </h1>
          <p className="text-gray-700 leading-relaxed mb-4">
            SLEEPZZONE es la marca y nombre comercial del Sitio, titularidad de{' '}
            <strong>SLEEPZZONE LAND SL</strong>.
          </p>

          <p className="text-gray-700 leading-relaxed mb-4">
            SleepZZone (www.sleepzzone.com) es la Primera Plataforma de
            Telemedicina Personalizada del Sueño en Europa para empresas y
            empleados que, usando una Medical Wellness App, aplica IA con un
            Método SleepZZone propio para la Evaluación del Sueño qué afecta,
            según la OMS, al 50% de la población mundial después del COVID.
          </p>

          <p className="text-gray-700 leading-relaxed mb-4">
            En nuestra oferta de servicios SAAS B2B para empresas saludables
            diseñamos e implantamos Planes Corporativos del Sueño. Somos una
            solución, en un solo Click, para las personas qué duermen mal con un
            equipo multidisciplinar de profesionales.
          </p>

          <p className="text-gray-700 leading-relaxed mb-4">
            La Solución SleepZZone, permite a nuestros usuarios tener una
            Valoración con 13 perfiles de patologías del sueño, a través de una
            Medical Wellness App, única en el mundo por ofrecer estos servicios
            basada en la metodología elaborada por nuestro Consejo Asesor.
          </p>
        </section>

        <section className="mb-8">
          <h1 className="text-2xl font-semibold text-gray-800 mb-4">
            2. Identificación de SLEEPZZONE
          </h1>
          <p className="text-gray-700 leading-relaxed mb-4">
            En cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de
            la Sociedad de la Información y de Comercio Electrónico, SLEEPZZONE
            facilita a los Usuarios del Sitio los siguientes datos de
            información general:
          </p>

          <ul className="list-disc pl-6 space-y-2 text-gray-700 leading-relaxed">
            <li>Denominación social: SLEEPZZONE LAND SL</li>
            <li>Nombre comercial: SLEEPZZONE</li>
            <li>Número de Identificación Fiscal: B88586219</li>
            <li>Domicilio Social: CALLE ORFILA, 7 2 º. 28010, MADRID</li>
            <li>Email de contacto: info@sleepzzone.com</li>
            <li>Teléfono de contacto: 865 660 465 / 603 367 585</li>
            <li>
              Inscrita en el registro mercantil de Madrid, al tomo 40245, folio
              37, hoja número M-715027, inscripción 1ª.
            </li>
          </ul>
        </section>
      </div>
      <FooterSmall />
    </div>
  );
};

export default ConditionsPage;
