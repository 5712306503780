import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Banner() {
  const { t } = useTranslation();
  return (
    <>
      <div className="banner-sec relative">
        <div className="wrapper mx-auto pt-24 pb-10 lg:py-0 px-5 lg:px-0">
          <div className="flex relative flex-col-reverse lg:flex-row">
            <div className="lg:w-6/12">
              <div className="banner-text py-5 lg:py-0 lg:pt-36 lg:pb-12">
                <h1 className="text-white font-black mb-3 text-3xl lg:text-5xl">
                  {t('Banner.titulo')}
                </h1>
                <p className="text-white text-xl">{t('Banner.subtitulo')}</p>
                <div className="banner-buttons flex flex-col lg:flex-row lg:gap-4 gap-y-4 lg:gap-y-0 mt-6 lg:mt-12">
                  <a
                    href="/home/#solutions"
                    className="rounded-lg bg-white filled-btn py-3.5 px-6 font-black border-2 border-white"
                  >
                    {t('Banner.Soy empresa')}
                  </a>
                  <a
                    href="/home/#howStart"
                    className="rounded-lg py-3.5 text-white px-6 font-black border-2 border-white"
                  >
                    {t('Banner.Soy empleado')}
                  </a>
                  <a
                    href="/home/#howStart"
                    className="rounded-lg py-3.5 px-6 text-white font-black border-2 border-white"
                  >
                    {t('Banner.Soy particular')}
                  </a>
                </div>
                <p className="text-white text-sm text-center mt-16">
                  {t('Banner.texto1')}
                </p>
                <p className="text-white text-sm text-center">
                  {t('Banner.texto2')}
                </p>
              </div>
            </div>
            <div className="lg:w-6/12 lg:pt-20">
              <img
                className="pt-2"
                src="/images/banner-img.svg"
                alt="Chica descansando, muestra buena salud y felicidad"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="dark-bar py-12">
        <div className="wrapper mx-auto px-5">
          <div className="logo-list grid grid-cols-2 gap-4 lg:flex lg:justify-between lg:items-center">
            <div className="logo-box opacity-50">
              <a
                href="https://www.abc.es/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/images/ABC.svg" alt="Logo de ABC" />
              </a>
            </div>
            <div className="logo-box opacity-50">
              <a
                href="https://cadenaser.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/images/CadenaSer.svg" alt="Logo de Cadena SER" />
              </a>
            </div>
            <div className="logo-box opacity-50">
              <a
                href="https://madridehealth.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/images/Mad-e-health.svg"
                  alt="Logo de Cluster MAD e-Health"
                />
              </a>
            </div>
            <div className="logo-box opacity-50">
              <a
                href="https://intereconomia.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/images/radioIntereconomia.svg"
                  alt="Logo de radio Intereconomía"
                />
              </a>
            </div>
            <div className="logo-box opacity-50">
              <a
                href="https://madfintech.es/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/images/MadFinTech.svg" alt="Logo de MAD FinTech" />
              </a>
            </div>
            <div className="logo-box opacity-50">
              <a
                href="https://www.prensaiberica.es/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/images/PrensaIberica.svg"
                  alt="Logo de Prensa Ibérica"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
