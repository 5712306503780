import React from "react";

const LoadingButton = ({
  loading,
  onClick,
  className,
  loadingMessage,
  children,
}) => {
  return (
    <button onClick={onClick} disabled={loading} className={className}>
      {loading ? loadingMessage : children}
    </button>
  );
};

export default LoadingButton;
