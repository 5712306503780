import React, { useState } from 'react'

const FAQAccordion = ({ items }) => {
    const [activeIndex, setActiveIndex] = useState(null);
  
    const onItemClick = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };
  
    return (
      <div className="accordion">
        {items.map((item, index) => (
          <div
            key={index}
            className={`py-4 px-5 rounded-lg mb-4 accordion-item ${activeIndex === index ? 'active' : ''}`}
            onClick={() => onItemClick(index)}>
            <div className="accordion-title relative font-semibold relative">{item.question}</div>
            {activeIndex === index && (
              <div className="accordion-content mt-4">{item.answer}</div>
            )}
          </div>
        ))}
      </div>
    );
  };
  
  export default FAQAccordion;