import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

export default function HowToStart() {
  const { loginWithRedirect } = useAuth0();
  const { t } = useTranslation();
  return (
    <>
      <section id="howStart" className="start-sec py-12 lg:py-0 lg:mb-32">
        <div className="wrapper mx-auto px-5 lg:p-0">
          <h2 className=" font-black text-3xl lg:text-5xl mb-4 lg:mb-8 text-center">
            {t('Howtostart.titulo')}
          </h2>
          <div className="flex flex-col lg:flex-row gap-8">
            <div className="how-box p-8 rounded-2xl bg-white">
              <h3 className="font-black">{t('Howtostart.subtitulo1')}</h3>
              <ul>
                <li>{t('Howtostart.texto11')}</li>
                <li>{t('Howtostart.texto12')}</li>
                <li>{t('Howtostart.texto13')}</li>
              </ul>
              <a
                href="/contact/business"
                className="rounded-lg text-white  mr-6 blue-btn px-6 font-black h-12 leading-10 border-4 border-white  mt-5 inline-block"
              >
                {t('Howtostart.boton1')}
              </a>
            </div>
            <div className="how-box p-8 rounded-2xl bg-white">
              <h3 className="font-black">{t('Howtostart.subtitulo2')}</h3>
              <ul>
                <li>{t('Howtostart.texto21')}</li>
                <li>{t('Howtostart.texto22')}</li>
                <li>{t('Howtostart.texto23')}</li>
              </ul>
              <button
                className="rounded-lg text-white  mr-6 blue-btn px-6 font-black h-12 leading-10 border-4 border-white  mt-5 inline-block"
                onClick={() => loginWithRedirect()}
              >
                {t('Howtostart.boton2')}
              </button>
            </div>
            <div className="how-box p-8 rounded-2xl bg-white">
              <h3 className="font-black">{t('Howtostart.subtitulo3')}</h3>
              <ul>
                <li>{t('Howtostart.texto31')}</li>
                <li>{t('Howtostart.texto32')}</li>
                <li>{t('Howtostart.texto33')}</li>
              </ul>
              <button
                className="rounded-lg text-white  mr-6 blue-btn px-6 font-black h-12 leading-10 border-4 border-white  mt-5 inline-block"
                onClick={() => loginWithRedirect()}
              >
                {t('Howtostart.boton3')}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
