import React, { useState } from 'react';
import { resendEmail } from '../api-service';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function Resend() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleResendClick = (e) => {
        e.preventDefault();

        resendEmail(email);
        navigate("/login")
    };

    return (
        <div className="containerResend">
        <h1 className="titleResend">{t("ResendPage.titulo")}</h1>
        <p className="subtitleResend">{t("ResendPage.subtitulo")}</p>
        <form>
            <input
            type="email"
            placeholder={t("ResendPage.placeholder")}
            value={email}
            onChange={handleEmailChange}
            id="resend-email-input"
            
            />
            <button 
                className="rounded-lg inline-block text-white blue-btn py-3.5 px-6 font-black border-2 mt-4"
                onClick={handleResendClick}>{t("ResendPage.boton")}</button>
        </form>
        </div>
    );
}

export default Resend;
