import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { useAuth } from '../auth.js';
import LoadingButton from './LoadingButton.js';
import { useNavigate } from 'react-router-dom';
import { delay } from 'q';
import { useTranslation } from 'react-i18next';

const BusinessForm = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [loading2, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [company, setCompany] = useState('');
  const [employeesNum, setEmployeesNum] = useState('');
  const [position, setPosition] = useState('');
  const { user } = useAuth();
  const handleSubmit = (e) => {
    e.preventDefault();

    sendEmail();

    if (!loading2) {
      navigate('/verification/business');
    }
  };

  const sendEmail = () => {
    const serviceId = 'sleepzzoneId';
    const templateId = 'template_hsnel1z';
    const userId = 'm_F9K9HuFgibYIL2W';

    const templateParams = {
      user_name: name,
      user_mail: email,
      user_telephone: telephone,
      user_company: company,
      user_employeesNum: employeesNum,
      user_position: position,
      user_code: user ? user.uid : 'Null',
    };
    setLoading(true);
    emailjs
      .send(serviceId, templateId, templateParams, userId)
      .then((response) => {
        console.log('Email sent successfully:', response);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });

    delay(5000).then(() => {
      setLoading(false);
    });
  };

  return (
    <div>
      <h2 className="text-xl lg:text-2xl mb-3 lg:mb-4 text-white font-black">
        {t('BusinessForm.rellena')}
      </h2>
      <form className="flex flex-col gap-6" onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            placeholder={t('BusinessForm.nombre')}
            onChange={(e) => setName(e.target.value)}
            className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            placeholder={t('BusinessForm.email')}
            onChange={(e) => setEmail(e.target.value)}
            className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            id="telephone"
            name="telephone"
            value={telephone}
            placeholder={t('BusinessForm.telefono')}
            onChange={(e) => setTelephone(e.target.value)}
            className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            id="company"
            name="company"
            value={company}
            placeholder={t('BusinessForm.empresa')}
            onChange={(e) => setCompany(e.target.value)}
            className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
          />
        </div>
        <div className="form-group">
          <input
            type="number"
            id="employeesNum"
            name="employeesNum"
            value={employeesNum}
            placeholder={t('BusinessForm.num')}
            onChange={(e) => setEmployeesNum(e.target.value)}
            className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            id="position"
            name="position"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
            placeholder={t('BusinessForm.cargo')}
            className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
          />
        </div>
        <LoadingButton
          loading={loading2}
          className="rounded-lg inline-block text-white blue-btn py-3.5 px-6 w-100 font-black border-2"
          loadingMessage={t('BusinessForm.enviando')}
        >
          {t('BusinessForm.boton')}
        </LoadingButton>
      </form>
    </div>
  );
};

export default BusinessForm;
