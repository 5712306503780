import React from "react";
import { useTranslation } from "react-i18next";

export default function Logosec() {
  const { t } = useTranslation();
  return (
    <>
      <section id="innovacion" className="logosec py-8 lg:py-16">
        <div className="wrapper mx-auto px-5 lg:px-0">
          <div className="flex flex-col lg:flex-row">
            <div className="lg:w-6/12">
              <div className="text-box-left lg:pr-16">
                <h3 className="font-black text-2xl lg:text-5xl mb-4">
                  {t("Logosec.titulo")}
                </h3>
                <p className="text-base lg:text-lg">
                  {t("Logosec.texto1")}
                  <br></br>
                  {t("Logosec.texto2")}
                </p>
              </div>
            </div>
            <div className="hidden lg:block lg:w-6/12">
              <div className="logo-img">
                <img src="/images/Innovation2.webp" alt="imagen con logos de empresas" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
