import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Banner() {
  const { t } = useTranslation();
  return (
    <>
      <div className="dark-bar text-white text-lg">
        <div className="wrapper mx-auto center py-2 px-2">
          <div className="flex relative flex-col md:flex-row justify-center items-center">
            <div class="md:mb-0 mb-2 text-center md:max-w-none max-w-[300px]">{t('Home.Abrimos ronda de inversión, invierte en nosotros desde 1000€')}</div>
            <a className="md:mb-0 mb-2 flex rounded-md md:ml-6 px-4 py-2 text-base font-normal blue-btn text-white " href="https://sleepzzone.fellowfunders.es/" target="_blank" rel="noreferrer">{t('Home.+info')}</a>
          </div>
        </div>
      </div>
    </>
  );
}
