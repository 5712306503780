import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../auth.js";


export default function CTA() {
  const { user, loading } = useAuth();
  const { t } = useTranslation();

  const boton2 = user? t("CtaSec.boton3") : t("CtaSec.boton2");
  return (
    <>
       <section className="cta-sec-outer py-8 lg:pt-12 lg:pb-24 px-5 lg:px-0">
            <div className="wrapper mx-auto">
                <div className="cta-inner flex flex-col lg:flex-row p-8 lg:p-16 rounded-xl lg:rounded-2xl text-center lg:text-left">
                    <h2 className="font-black text-xl lg:text-4xl text-white lg:pr-10 lg:leading-snug mb-6 lg:mb-0"> {t("CtaSec.titulo")}</h2>
                    <div className="cta-buttons">
                      <Link to="/contact/business" className="rounded-lg inline-block text-white w-[256px] text-lg mb-5 text-center leading-[60px] blue-btn h-[64px] px-6 font-black border-2">{t("CtaSec.boton1")}</Link>
                      <Link to="/register" className="rounded-lg inline-block text-white w-[256px] text-lg text-center leading-[60px] white-btn h-[64px] px-6 font-black border-2">{boton2}</Link>
                    </div>
                </div>
            </div>
        </section> 
    </>
  )
}
