export const timeHour = [
  {
    name: "00:00",
    value: "00:00:00",
  },
  {
    name: "1:00",
    value: "01:00:00",
  },
  {
    name: "2:00",
    value: "02:00:00",
  },
  {
    name: "3:00",
    value: "03:00:00",
  },
  {
    name: "4:00",
    value: "04:00:00",
  },
  {
    name: "5:00",
    value: "05:00:00",
  },
  {
    name: "6:00",
    value: "06:00:00",
  },
  {
    name: "7:00",
    value: "07:00:00",
  },
  {
    name: "8:00",
    value: "08:00:00",
  },
  {
    name: "9:00",
    value: "09:00:00",
  },
  {
    name: "10:00",
    value: "10:00:00",
  },
  {
    name: "11:00",
    value: "11:00:00",
  },
  {
    name: "12:00",
    value: "12:00:00",
  },
  {
    name: "13:00",
    value: "13:00:00",
  },
  {
    name: "14:00",
    value: "14:00:00",
  },
  {
    name: "15:00",
    value: "15:00:00",
  },
  {
    name: "16:00",
    value: "16:00:00",
  },
  {
    name: "17:00",
    value: "05:00:00",
  },
  {
    name: "18:00",
    value: "18:00:00",
  },
  {
    name: "19:00",
    value: "19:00:00",
  },
  {
    name: "20:00",
    value: "20:00:00",
  },
  {
    name: "21:00",
    value: "21:00:00",
  },
  {
    name: "22:00",
    value: "22:00:00",
  },
  {
    name: "23:00",
    value: "23:00:00",
  },
];
