import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const selectedLanguage = localStorage.getItem('selectedLanguage') || 'es';

i18next.use(Backend).use(initReactI18next).init({
  fallbackLng: 'es',
  lng: selectedLanguage,
});

export default i18next;
