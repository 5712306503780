import React, { useState } from 'react';

export default function Additionalinfo() {
  const [Age, setAge] = useState({});
  const [Medication, setMedication] = useState({});
  const [Weight, setWeight] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const [yesNoOptions1, setYesNoOptions1] = useState({});
  const [yesNoOptions2, setYesNoOptions2] = useState({});
  const [yesNoOptions3, setYesNoOptions3] = useState({});

  const handleSelectChange = (event, selectName) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [selectName]: event.target.value,
    }));
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAge((prevData) => ({
      ...prevData,
      [Age]: value,
    }));

    setWeight((prevData) => ({
      ...prevData,
      [Weight]: value,
    }));

    setMedication((prevData) => ({
      ...prevData,
      [Medication]: value,
    }));
  };

  const handleYesNoChange1 = (event, fieldName) => {
    setYesNoOptions1((prevOptions) => ({
      ...prevOptions,
      [fieldName]: event.target.value,
    }));
  };

  const handleYesNoChange2 = (event, fieldName) => {
    setYesNoOptions2((prevOptions) => ({
      ...prevOptions,
      [fieldName]: event.target.value,
    }));
  };
  const handleYesNoChange3 = (event, fieldName) => {
    setYesNoOptions3((prevOptions) => ({
      ...prevOptions,
      [fieldName]: event.target.value,
    }));
  };

  return (
    <>
      <div className="login-page py-16 relative">
        <div className="wrapper mx-auto">
          <div className="additiona-info mx-auto">
            <div className="add-header text-center">
              <a href="/" className="login-logo text-center">
                <img
                  className="inline-block"
                  src="/images/logo.png"
                  alt="SleepZzone logo"
                />{' '}
              </a>
              <h3 className="text-white mt-12	  pb-6 head-3 font-black">
                Welcome to SleepZzone
              </h3>
            </div>
            <div className="additional-box">
              <form>
                <div className="form-group mb-5">
                  <label
                    htmlFor="select1"
                    className="text-xl text-white font-black	mb-2 block"
                  >
                    Genre
                  </label>
                  <select
                    className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
                    id="select1"
                    value={selectedOptions.select1 || ''}
                    onChange={(e) => handleSelectChange(e, 'select1')}
                  >
                    <option value="">Male</option>
                    <option value="option1">Female</option>
                  </select>
                </div>

                <div className="form-group mb-5">
                  <label
                    htmlFor="select2"
                    className="text-xl text-white font-black	mb-2 block"
                  >
                    Age
                  </label>
                  <input
                    className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
                    placeholder="Enter Your Age"
                    type="text"
                    value={setAge.Age}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label
                    htmlFor="select3"
                    className="text-xl text-white font-black	mb-2 block"
                  >
                    Height
                  </label>
                  <div className="grid grid-cols-2 gap-x-5 ">
                    <div className="form-group mb-5">
                      <select
                        className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
                        id="select2"
                        value={selectedOptions.select2 || ''}
                        onChange={(e) => handleSelectChange(e, 'select2')}
                      >
                        <option value="">Feet</option>
                        <option value="option1">3</option>
                        <option value="option2">4</option>
                        <option value="option3">5</option>
                      </select>
                    </div>
                    <div className="form-group mb-5">
                      <select
                        className="input-login w-full h-12 border-0 rounded-lg text-white px-6 block"
                        id="select3"
                        value={selectedOptions.select3 || ''}
                        onChange={(e) => handleSelectChange(e, 'select3')}
                      >
                        <option value="">Inch</option>
                        <option value="option1">1</option>
                        <option value="option2">2</option>
                        <option value="option3">3</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="select3"
                    className="text-xl text-white font-black	mb-2 block"
                  >
                    Weight
                  </label>
                  <div className="grid grid-cols-8 gap-x-5 ">
                    <div className="form-group mb-5 col-span-6">
                      <input
                        className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
                        placeholder="Enter Your Age"
                        type="text"
                        value={setMedication.Medication}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="form-group mb-5 col-span-2">
                      <select
                        className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
                        id="select3"
                        value={selectedOptions.select3 || ''}
                        onChange={(e) => handleSelectChange(e, 'select3')}
                      >
                        <option value="">lbs</option>
                        <option value="option1">kgs</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="form-group mb-5">
                  <label
                    htmlFor="select3"
                    className="text-xl text-white font-black	mb-2 block"
                  >
                    Do you suffer from diabetes?
                  </label>
                  <div className="yes-no-btn">
                    <label>
                      <input
                        type="radio"
                        value="yes"
                        checked={yesNoOptions1.question1 === 'yes'}
                        onChange={(e) => handleYesNoChange1(e, 'question1')}
                      />
                      <span>Yes</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="no"
                        checked={yesNoOptions1.question1 === 'no'}
                        onChange={(e) => handleYesNoChange1(e, 'question1')}
                      />
                      <span>No</span>
                    </label>
                  </div>
                </div>

                <div className="form-group mb-5">
                  <label
                    htmlFor="select3"
                    className="text-xl text-white font-black	mb-2 block"
                  >
                    Do you have any allergies?
                  </label>
                  <div className="yes-no-btn">
                    <label>
                      <input
                        type="radio"
                        value="yes"
                        checked={yesNoOptions2.question2 === 'yes'}
                        onChange={(e) => handleYesNoChange2(e, 'question2')}
                      />
                      <span> Yes</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="no"
                        checked={yesNoOptions2.question2 === 'no'}
                        onChange={(e) => handleYesNoChange2(e, 'question2')}
                      />
                      <span>No</span>
                    </label>
                  </div>
                </div>

                <div className="form-group mb-12">
                  <label
                    htmlFor="select4"
                    className="text-xl text-white font-black	mb-2 block"
                  >
                    Do you take any medication?
                  </label>
                  <input
                    className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
                    placeholder="Enter Your Age"
                    type="text"
                    value={setMedication.Medication}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <button className="rounded-lg inline-block text-white w-full blue-btn py-3.5 px-6 font-black border-2">
                  Start with SleepZzone
                </button>
              </form>

              <div className="footer-login mt-24 text-center">
                <p>
                  © 2024 SleepZzone · <a className="underline">Privacidad</a> ·{' '}
                  <a className="underline">Condiciones de uso</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
