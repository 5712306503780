import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './firebase.config';
import { Auth0Provider } from '@auth0/auth0-react';
import i18n from './i18n/i18n.js';
import { AuthProvider } from './auth.js';

const selectedLanguage = localStorage.getItem('selectedLanguage') || 'es';
i18n.changeLanguage(selectedLanguage);

initializeApp(firebaseConfig);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      authorizationParams={{
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      }}
    >
      <AuthProvider>
        <App />
      </AuthProvider>
    </Auth0Provider>
  </React.StrictMode>
);
