import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="text-center py-12">
      <h1 className="text-4xl text-gray-800">Página no encontrada</h1>
      <p className="text-lg text-gray-500 mt-5 mb-5">
        Lo sentimos, la página que buscas no se encuentra disponible.
      </p>
      <Link
        to="/"
        className="inline-block px-5 py-2 bg-blue-500 text-white rounded transition-colors duration-300 hover:bg-blue-700"
      >
        Volver a la página de inicio
      </Link>
    </div>
  );
};

export default NotFound;
