import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../../auth.js';
import * as apiService from '../../api-service.js';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Preloader from '../../component/Preloader.js';
import LoadingButton from '../../component/LoadingButton';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import SideMenu from '../../component/SideMenu.js';
import { useAuth0 } from '@auth0/auth0-react';

export default function ProfileSetting() {
  const { t } = useTranslation();
  const { user, loading, signOut } = useAuth();
  const [dataState, setDataState] = useState(undefined);
  const userDataRef = useRef({});
  const navigate = useNavigate();
  const [loading2, setLoading] = useState(false);
  const { logout } = useAuth0();

  useEffect(() => {
    (async () => {
      if (!loading) {
        if (user) {
          setDataState('loading');
          const userIdToken = await user.getIdToken();
          try {
            const { userData } = await apiService.getUserData({
              userIdToken,
              userId: user.uid,
            });

            userDataRef.current = userData;

            setName(userDataRef.current.name || '');
            setLastName(userDataRef.current.lastName || '');
            setEmail(userDataRef.current.email || '');
            setTelephone(userDataRef.current.telephone || '');
            setDataState('success');
          } catch {
            setDataState('error');
          }
        }
      }
    })();
  }, [user, loading]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const userData = {
      name,
      email,
      lastName,
      telephone,
      premium: userDataRef.current.premium,
      premium_timestamp: userDataRef.current.premium_timestamp,
      premium_type: userDataRef.current.premium_type,
      premium_code: userDataRef.current.premium_code,
    };

    setLoading(true);
    const userIdToken = await user.getIdToken();

    try {
      const success = await apiService.updateUserData({
        userIdToken,
        userId: user.uid,
        userData,
      });
      setLoading(false);

      if (success) {
        // ???
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setDataState('error');
    } finally {
      setLoading(false);
    }
  };

  const handleSignOut = async () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
    await signOut();
    navigate('/');
  };

  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'telephone':
        setTelephone(value);
        break;
      default:
        break;
    }
  };

  const child = loading ? (
    <></>
  ) : user ? (
    dataState === 'loading' ? (
      <Preloader></Preloader>
    ) : dataState === 'error' ? (
      <p>An error occured.</p>
    ) : dataState === 'success' ? (
      <div className="login-page relative">
        <div className="wrapper mx-auto px-5 lg:px-0">
          <nav>
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto py-4">
              <a href="/" className="flex items-center">
                <img
                  src="/images/logo.webp"
                  className="h-8 mr-3"
                  alt="SleepZzone Logo"
                />
              </a>

              <div className="w-full md:block md:w-auto" id="navbar-default">
                <ul className="font-medium flex justify-between lg:justify-normal items-center lg:p-4 md:p-0 mt-4 md:space-x-8 md:mt-0 md:border-0">
                  <li>
                    <div className="profile-bt-text">
                      <h4 className="text-base font-semibold opacity-60 text-white">
                        {t('Profile.bienvenido')} {userDataRef.current.name}
                      </h4>
                      <p className="text-xs text-white premium-text font-medium">
                        {userDataRef.current.premium ? 'Premium' : 'Premium'}
                      </p>
                    </div>
                  </li>
                  <li>
                    <button
                      onClick={handleSignOut}
                      className="rounded-lg inline-block text-white text-base bg-zinc-800 hover:bg-zinc-700 py-2 px-6 font-black"
                    >
                      {t('Profile.cerrar')}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          <div class="flex">
            <div className="button-column-profile pt-4 lg:pt-8 pb-12">
              <SideMenu />
            </div>
            <div className="pt-4 lg:pt-8 pb-12">
              <div>
                <h3 className="text-white text-2xl pb-4 lg:text-4xl font-black">
                  {t('Profile.titulo')}
                </h3>
                <div className="button-top mt-8 flex gap-8 text-lg justify-between">
                  <Link to="/profile">
                    <button class="h-16 rounded-lg text-white gray-btn hover:scale-110 hover:bg-sky-500 transition duration-500 px-4 font-semibold lg:px-6 lg:font-black">
                      {t('Profile.valoration')}
                    </button>
                  </Link>
                  <Link to="/PersonalInfo">
                    <button class="h-16 rounded-lg text-white gray-btn hover:scale-110 hover:bg-sky-500 transition duration-500 px-4 font-semibold lg:px-6 lg:font-black">
                      {t('Profile.info')}
                    </button>
                  </Link>
                  <Link to="/clinicHistory">
                    <button class="h-16 rounded-lg text-white gray-btn hover:scale-110 hover:bg-sky-500 transition duration-500 px-4 font-semibold lg:px-6 lg:font-black">
                      {t('Profile.clinic')}
                    </button>
                  </Link>
                </div>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="form-group mt-12 mb-5">
                  <label
                    htmlFor="select2"
                    className="text-xl text-white font-black	mb-2 block"
                  >
                    {t('Profile.nombre')}
                  </label>
                  <input
                    name="name"
                    className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
                    placeholder={t('Profile.placeholderNombre')}
                    type="text"
                    value={name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group mb-5">
                  <label
                    htmlFor="select2"
                    className="text-xl text-white font-black	mb-2 block"
                  >
                    {t('Profile.apellido')}
                  </label>
                  <input
                    name="lastName"
                    className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
                    placeholder={t('Profile.placeholderApellido')}
                    type="text"
                    value={lastName}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group mb-5">
                  <label
                    htmlFor="select2"
                    className="text-xl text-white font-black	mb-2 block"
                  >
                    {t('Profile.correo')}
                  </label>
                  <input
                    name="email"
                    className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
                    placeholder={t('Profile.placeholderCorreo')}
                    type="email"
                    value={email}
                    readOnly
                    required
                  />
                </div>
                <div className="form-group mb-5">
                  <label
                    htmlFor="select2"
                    className="text-xl text-white font-black	mb-2 block"
                  >
                    {t('Profile.numero')}
                  </label>
                  <input
                    name="telephone"
                    className="input-login w-full h-12 border-0 rounded-lg text-white px-6"
                    placeholder={t('Profile.placeholderNumero')}
                    type="text"
                    value={telephone}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <LoadingButton
                  loading={loading2}
                  onClick={handleSubmit}
                  className="rounded-lg text-white blue-btn py-2 px-4 font-semibold lg:px-6 lg:font-black profile-list-button"
                  loadingMessage={t('Profile.actualizando')}
                >
                  {t('Profile.boton')}
                </LoadingButton>
              </form>
            </div>
          </div>
        </div>
      </div>
    ) : undefined
  ) : (
    <div className="login-page relative">
      <div className="wrapper mx-auto px-5 lg:px-0 text-center py-10 lg:py-20">
        <h4 className="text-4xl mb-4">You're not logged in</h4>
        <a
          href="/"
          className="rounded-lg inline-block text-white text-base bg-zinc-800 hover:bg-zinc-700 py-2 px-6 font-black"
        >
          Back to home
        </a>
      </div>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>
          Perfil - Información Personal: Modificar el nombre, correo y número de
          teléfono para contactar con nostros y mejorar tu sueño con nosotros |
          SleepZzone{' '}
        </title>
        <meta
          name="description"
          content="Con tu pefil completo, podrás conocer más sobre tus hábitos de sueño, consejos para dormir mejor y cómo tus experiencias pueden ayudarte a lograr una noche de sueño más reparadora."
        />
        <meta
          name="keywords"
          content="Dormir, descansar, bienestar, descanso, sueño reparador, consejos para dormir, rendimiento, aprender a dormir"
        />
      </Helmet>
      <div>{child}</div>;
    </>
  );
}
