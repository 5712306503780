import React from 'react';
import Navbar from '../component/Navbar.js';
import Footer from '../component/Footer.js';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Team from '../component/HomeComponent/Team.js';

export default function News() {
  const { t } = useTranslation();
  const isHomePage = true;
  const newses = [
    {
      img: 'images/industryTalks.webp',
      title: t('knowus.NoticiaTitulo1'),
      discription: t('knowus.NoticiaTexto1'),
      href: 'https://www.linkedin.com/posts/francisco-bas-b-sc-m-a-mba-iese-49768015_francisco-bas-ceo-de-sleepzzone-un-plan-activity-7199778633379389443-R86Q/?utm_source=share&utm_medium=member_desktop',
    },
    {
      img: 'images/pons.webp',
      title: t('knowus.NoticiaTitulo2'),
      discription: t('knowus.NoticiaTexto2'),
      href: 'https://www.linkedin.com/posts/francisco-bas-b-sc-m-a-mba-iese-49768015_sueaeho-investigaciaejn-desarrollo-ugcPost-7212564187678461952-AjP6/?utm_source=share&utm_medium=member_desktop',
    },
    {
      img: 'images/pandemia.webp',
      title: t('knowus.NoticiaTitulo3'),
      discription: t('knowus.NoticiaTexto3'),
      href: 'https://www.linkedin.com/posts/francisco-bas-b-sc-m-a-mba-iese-49768015_sueaeho-infancia-protagonistas-activity-7173749961841332224-hN90/?utm_source=share&utm_medium=member_desktop',
    },
    {
      img: 'images/pioneras.webp',
      title: t('knowus.NoticiaTitulo4'),
      discription: t('knowus.NoticiaTexto4'),
      href: 'https://www.linkedin.com/posts/sleepzzone_26-abril-ven-al-encuentro-de-las-empresas-activity-7182282847121006592-KnDs/?utm_source=share&utm_medium=member_desktop,',
    },
  ];

  return (
    <>
      <Helmet>
        <title>Noticias: Novedades en Sueño y Bienestar | SleepZzone</title>
        <meta
          name="description"
          content="Mantente al tanto de las últimas noticias y novedades relacionadas con el sueño y el bienestar en SleepZzone. Aprenderás del tratamiento que necesitas para descansar y dormir mejor."
        />
        <meta
          name="keywords"
          content="Noticias, Novedades, Sueño, Bienestar, Últimas noticias"
        />
      </Helmet>
      <Navbar isHomePage={isHomePage} />
      <section className="bg-black relative">
        <div className="wrapper mx-auto px-5 lg:px-0">
          <div className="flex lg:flex-wrap flex-col-reverse lg:flex-row items-center z-10 justify-between pt-28 lg:pt-40 pb-12 lg:pb-36">
            <div className="sleepbot-left-banner max-w-[700px]">
              <h2 className="relative mb-3 z-10 font-black text-white text-2xl lg:text-5xl">
                <strong>{t('knowus.titulo')}</strong>
              </h2>
              <p className="opacity-70 text-base lg:text-xl text-white leading-relaxed">
                {t('knowus.subtitulo1')}
              </p>
              <br />
              <p className="opacity-90 text-base lg:text-xl text-white leading-relaxed">
                <strong> {t('knowus.subtitulo2')}</strong>
              </p>
            </div>
            <div className="hidden sleepbot-logo rounded-lg lg:rounded-[40px] lg:flex items-center justify-center px-6 py-3 lg:px-14 lg:w-[369px] lg:h-[369px] mb-6 lg:mb-0">
              <img
                className="inline-block"
                src="/images/logo.webp"
                alt="logo de SleepZzone"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="news-sec py-8 lg:py-16">
        <div className="wrapper mx-auto px-5 lg:px-0">
          <h2 className="relative z-10 font-black text-3xl lg:text-5xl text-center mb-6 lg:mb-12">
            {t('knowus.Noticias')}
          </h2>
          <div className="grid gap-6 lg:grid-cols-2 lg:gap-12">
            {newses.map((news, index) => {
              return (
                <div className="news-box overflow-hidden rounded-2xl">
                  <div className="news-img relative">
                    <a href={news.href} rel="noreferrer" target="_blank">
                      <img src={news.img} alt="Imagen de noticia de LinkedIn" />
                    </a>
                  </div>
                  <div className="news-detail  p-8">
                    <h3 className="font-black text-lg lg:text-2xl mb-1">
                      {news.title}
                    </h3>
                    <p className="text-base lg:text-lg opacity-70">
                      {news.discription}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="news-bottom text-center flex justify-center mt-12 items-center">
            <p className="text-[21px] opacity-70">
              {t('knowus.subtituloNoticias')}
            </p>{' '}
            <a
              href="https://www.linkedin.com/company/sleepzzone/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="mx-4"
                src="/images/linkdn-ico.svg"
                alt="LinkedIn"
              />
            </a>{' '}
          </div>
        </div>
      </section>
      <section className="team-sec bg-neutral-100 mb-20 relative py-8 lg:py-16">
        <div className="wrapper mx-auto px-5 lg:px-0">
          <Team></Team>
        </div>
      </section>
      <section className="colm-sec">
        <div className="wrapper mx-auto px-5 lg:px-0">
          <div className="grid items-center lg:py-16 lg:grid-cols-2 gap-x-16">
            <div className="join-img mb-6 lg:mb-0">
              <img
                src="/images/join-img.webp"
                alt="Logo de SleepZzone en blanco"
              />
            </div>
            <div className="join-text">
              <h2 className="font-black text-3xl lg:text-5xl mb-4">
                {t('knowus.tituloCrece')}
              </h2>
              <p className="text-base lg:text-xl opacity-70">
                {' '}
                {t('knowus.textoCrece')}
              </p>
              <a
                href="/contact/work"
                className="rounded-lg text-white  mr-6 blue-btn px-6 font-black h-12 leading-10 border-4 border-white  mt-8 inline-block"
              >
                {t('knowus.botonCrece')}
              </a>
            </div>
          </div>
          <div className="grid items-center py-10	lg:py-16 lg:grid-cols-2 gap-x-15">
            <div className="join-text">
              <p className="text-base font-semibold opacity-40 mb-2 lg:mb-4">
                {t('knowus.cabeceraColaboradores')}
              </p>
              <h2 className="font-black text-2xl lg:text-4xl mb-4 ">
                <strong> {t('knowus.tituloColaboradores')}</strong>
              </h2>
              <p className="text-base lg:text-xl">
                <strong>{t('knowus.subtituloColaboradores')}</strong>
              </p>
              <br />
              <p className="text-base lg:text-xl opacity-70">
                {t('knowus.textoColaboradores')}
              </p>
              <a
                href="/contact/collaborate"
                className="rounded-lg text-white  mr-6 blue-btn px-6 font-black h-12 leading-10 border-4 border-white  mt-8 inline-block"
              >
                {t('knowus.botonColaboradores')}
              </a>
            </div>
            <div className="join-img hidden lg:block">
              <img
                src="/images/foto_circular.webp"
                alt="Foto de médico en una vídeoconsulta"
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
