
import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api`

export async function signIn({ uuid }) {
  const url = `${apiUrl}/login`;
  const res = await axios.post(url, { uuid });
  return {
    token: res.data.token,
    isVerified: res.data.isVerified
  }
}

export async function signUp({
  name,
  email,
  password,
  telephone,
  disCode,
  lastName,
  uuid,
}) {
  const url = `${apiUrl}/register`
  const res = await axios.post(url, {
    name,
    lastName,
    email,
    password,
    telephone,
    disCode,
    uuid,
  });
  return res.data;
}

export async function getUserData({ userIdToken, userId }) {
  const url = `${apiUrl}/users/${userId}`
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${userIdToken}`,
    },
  });

  return {
    userData: res.data.userData,
    clinicHistory: res.data.clinicHistory,
    userDoc: res.data.userDoc,
  };
}

export async function updateUserData({ userIdToken, userId, userData }) {
  const url = `${apiUrl}/users/${userId}`
  const res = await axios.put(
    url,
    {
      name: userData.name ?? null,
      email: userData.email ?? null,
      lastName: userData.lastName ?? null,
      telephone: userData.telephone ?? null,
      premium: userData.premium ?? null,
      premium_timestamp: userData.premium_timestamp ?? null,
      premium_type: userData.premium_type ?? null,
      premium_code: userData.premium_code ?? null,
    },
    {
      headers: {
        Authorization: `Bearer ${userIdToken}`
      }
    }
  )

  return res.data
}

export async function setClinicHistoryData({
  userIdToken,
  userId,
  clinicHistory
}) {
  const url = `${apiUrl}/users/clinicHistory/${userId}`
  const res = await axios.post(
    url,
    {
      imc: clinicHistory.imc,
      age: clinicHistory.age,
      allergy: clinicHistory.allergy,
      diabetes: clinicHistory.diabetes,
      genre: clinicHistory.genre,
      height: clinicHistory.height,
      medication: clinicHistory.medication,
      weight: clinicHistory.weight
    },
    {
      headers: {
        Authorization: `Bearer ${userIdToken}`
      }
    }
  )

  return true
}

export async function getForm({ userIdToken, form }) {
  const url = `${apiUrl}/forms/${form}`

  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${userIdToken}`
    }
  })

  return res.data
}

export async function pushForm({
  userIdToken,
  userId,
  currentQuestionnair,
  resultsArray
}) {
  const url = `${apiUrl}/algorithm/${currentQuestionnair}`

  const res = await axios.post(
    url,
    {
      results: resultsArray,
      usrId: userId
    },
    {
      headers: {
        Authorization: `Bearer ${userIdToken}`
      }
    }
  )

  return res.data
}

export async function pushValoration({ userIdToken, userId }) {
  const url = `${apiUrl}/algorithm/Valoration`
  const res = await axios.post(
    url,
    {
      usrId: userId
    },
    {
      headers: {
        Authorization: `Bearer ${userIdToken}`
      }
    }
  )
  return res.data
}

export async function setConsultation({ userIdToken, userId, consultation }) {
  const url = `${apiUrl}/consultation/${userId}/${consultation}`

  const res = await axios.post(url, null, {
    headers: {
      Authorization: `Bearer ${userIdToken}`
    }
  })
  return res.data
}

export async function resendEmail(email) {
  const url = `${apiUrl}/resendEmail`
  const res = await axios.post(url, { email })

  return res.data
}

export async function changePassword(email) {
  const url = `${apiUrl}/changePassword`
  const res = await axios.post(url, { email })

  return res.data
}

export async function getAssesmentIndex(userIdToken, id) {
  const url = `${apiUrl}/users/assesment/${id}`
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${userIdToken}`
      }
    })

    return response.data.index
  } catch (error) {
    // TODO fix this
    return 0
  }
}

export async function getProfiles(userIdToken, id) {
  const url = `${apiUrl}/users/profiles/${id}`
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${userIdToken}`,
      },
    });

    return response.data;
  } catch (error) {
    // TODO fix this
    return 0
  }
}

export async function getCurrentPittsburg(userIdToken, id) {
  const url = `${apiUrl}/users/currentPittsburg/${id}`
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${userIdToken}`
      }
    })
    return response.data
  } catch (error) {
    // TODO fix this
    return 0
  }
}

export const resendAuth0VerificationEmail = async (uuid) => {
  const url = `${apiUrl}/emailVerification/${uuid}`;

  try {
    const response = await axios.get(url);
    return response.data; 
  } catch (error) {
    throw new Error('Email could not be resend')
  }
}