import React, { useState } from "react";
import { changePassword } from "../api-service";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function ForgotPasswordPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleResendClick = (e) => {
    e.preventDefault();

    changePassword(email);
    navigate("/login");
  };

  return (
    <div className="containerResend">
      <h1 className="titleResend">{t("ForgotPassword.titulo")}</h1>
      <p className="subtitleResend">{t("ForgotPassword.subtitulo")}</p>
      <form>
        <input
          type="email"
          placeholder={t("ForgotPassword.placeholder")}
          value={email}
          onChange={handleEmailChange}
          id="resend-email-input"
        />
        <button
          className="rounded-lg inline-block text-white blue-btn py-3.5 px-6 font-black border-2 mt-4"
          onClick={handleResendClick}
        >
          {t("ForgotPassword.boton")}
        </button>
      </form>
    </div>
  );
}

export default ForgotPasswordPage;
